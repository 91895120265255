import Swiper, { Navigation } from "swiper";
import { updateProgressbar } from "../utils";

(function () {
  const caseStudiesWidgets = document.querySelectorAll(".js-caseStudies");

  caseStudiesWidgets.forEach(function (widget) {
    const carousel = widget.querySelector(".js-caseStudiesCarousel");
    const controls = widget.querySelector(".js-carouselControls");
    const slides = carousel.querySelectorAll(".swiper-slide");

    const progressbarEl = controls.querySelector(".js-carouselProgressBar");

    const nextBtn = controls.querySelector(".js-carouselNext");
    const prevBtn = controls.querySelector(".js-carouselPrev");

    const swiper = new Swiper(carousel, {
      modules: [Navigation],
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
      speed: 500,
      slidesPerView: 1,
      spaceBetween: 16,
      breakpoints: {
        1024: {
          speed: 750,
        },
      },
      on: {
        init: (s) => {
          updateProgressbar(progressbarEl, s);
        },
        slideChange: (s) => {
          updateProgressbar(progressbarEl, s);
        },
      },
    });
  });
})();
